/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./index.css";
import { MetaTags } from "react-meta-tags";
import { useZendesk } from "../../../hooks/useZendesk";

export default function Termos() {
  const empresa = process.env.REACT_APP_NAME_EMPRESA || "";
  const link = process.env.REACT_APP_LINK_EMPRESA || "";
  const email = process.env.REACT_APP_MAIL_EMPRESA2 || "";
  const empresaLowerCase = empresa.toLowerCase();
  const { hide } = useZendesk();

  useEffect(() => {
    window.scrollTo(0, 0);
    hide();
  }, []);

  return (
    <>
      <MetaTags>
        <meta name="kdt:page" content="terms-of-use" />
      </MetaTags>
      <div className="container info-containers mt-4 mb-4">
        <div className="row terms">
          <h1>TERMOS DE USO</h1>
          <p>
            A {empresa} é uma empresa que oferece soluções tecnológicas para
            gestão de eventos. Sua plataforma integra a venda de ingressos
            online e físicos, bem como a gestão de consumo através de hardware
            único que permite a venda de produtos e serviços no sistema de
            fichas ou cashless. O conteúdo deste documento espelha a forma
            através da qual se regem as relações entre a {empresa}, os
            organizadores de eventos e seus consumidores. Ao acessar os recursos
            tecnológicos disponibilizados pela {empresa}, o organizador do
            evento e/ou seu cliente concordam com este Termo e com suas futuras
            atualizações. Neste Termo, o organizador de evento será denominado
            “Organizador”. Os consumidores que adquirem ingressos ou reservam
            lugares em eventos através da {empresa}
            serão denominados “Consumidores”. Quando em conjunto, “Organizador”
            e “Consumidores”, bem como as pessoas que navegam na plataforma{" "}
            {empresa}, serão denominados “Usuários”. Para fins deste
            instrumento, o termo “plataforma” ou “ferramentas” referem-se a
            quaisquer instrumentos disponibilizados pela
            {empresa} para a divulgação e comercialização de ingressos online e
            físicos, bem como a gestão de consumo através de hardware único que
            permite a venda de produtos e serviços no sistema de fichas ou
            cashless.
          </p>

          <h4>
            <strong>1. Informações gerais</strong>
          </h4>

          <p>
            1.1. Os presentes Termos regulam os serviços oferecidos na
            plataforma {empresa}, que consistem em: (i) disponibilizar uma
            solução tecnológica (plataforma {empresa}) para que Organizadores
            possam criar, organizar, gerir e divulgar seus próprios eventos;
            (ii) viabilizar, por meio da plataforma, as operações de venda de
            ingressos cadastrados por usuários Organizadores; (iii) hospedar os
            eventos criados pelos Organizadores; (iv) disponibilizar aos
            consumidores a compra on-line de ingressos para os eventos
            publicados pelos Organizadores no site {link}.
            <br />
            1.2. Os eventos criados e divulgados através da plataforma
            {empresa} são de responsabilidade exclusiva dos próprios
            Organizadores, os quais são os únicos responsáveis por estabelecer
            as condições de venda, realização e cancelamento dos eventos. A
            {empresa} não é criadora, co-organizadora ou proprietária dos
            eventos hospedados em sua plataforma, porém poderá monitorar os
            eventos criados, a fim de que estes cumpram suas políticas legais.
            <br />
            1.3. Toda e qualquer informação ou publicidade referente ao evento
            que esteja disponível na plataforma {empresa} constitui conteúdo de
            propriedade e responsabilidade exclusiva dos Organizadores do
            evento, sendo este, ainda, o responsável exclusivo pelo cumprimento
            da oferta relativa ao evento divulgado.
            <br />
            1.4. A {empresa} envida seus esforços para a disponibilidade
            contínua e permanente de sua plataforma. No entanto, poderá ocorrer,
            eventualmente, alguma indisponibilidade temporária decorrente de
            manutenção necessária ou mesmo gerada por motivo de força maior,
            como desastres naturais, falhas ou colapsos nos sistemas centrais de
            comunicação e acesso à internet ou, fatos de terceiro que fogem de
            sua esfera de vigilância e responsabilidade.
            <br />
            1.5. O Consumidor isenta a {empresa} de qualquer dano nos casos de
            paralisações, parciais ou totais, dos seus serviços, decorrentes de
            falta temporária de energia elétrica, de pane nos serviços das
            concessionárias, de falhas nos serviços oferecidos pelas redes
            telefônicas e outros provedores, inclusive provedores de hospedagem,
            de greves, tumultos ou quaisquer outros fatos que não estejam
            diretamente ligados ao serviço. A {empresa} também não será
            responsável por qualquer dano causado por motivo de caso fortuito ou
            força maior, bem como fato de terceiro.
            <br />
          </p>

          <h4>
            <strong>2. Cadastro de usuários</strong>
          </h4>
          <p>
            2.1. O cadastro na plataforma {empresa} é gratuito, no entanto, os
            serviços oferecidos serão cobrados de acordo com o estabelecido pela{" "}
            {empresa} nestes Termos de Uso. <br />
            2.2. Para utilização da plataforma {empresa} o Usuário deverá ser
            cadastrado no site {link}, declarando que suas informações de
            cadastro são verdadeiras, assumindo, ainda, o compromisso de
            atualizar os dados constantes sempre que necessário ou requerido.{" "}
            <br />
            2.3. A {empresa} poderá suspender os serviços ou encerrar a conta de
            qualquer Usuário, especialmente se forem identificados indícios de
            que o cadastro foi preenchido com informações incompletas,
            desatualizadas ou falsas e, ainda, quando a conta estiver sendo
            utilizada de forma indevida ou suspeita. <br />
            2.4. A {empresa} não se responsabiliza pela veracidade ou
            assertividade dos dados inseridos no cadastro, sendo que a criação
            de perfis falsos em sua plataforma poderá ser considerada crime de
            falsa identidade, falsidade ideológica ou estelionato. <br />
            2.5. Os Usuários são os únicos responsáveis por eventuais falhas
            e/ou prejuízos decorrentes de cadastros realizados em desacordo com
            este Termo e com as instruções da plataforma {empresa}. <br />
            2.6. Sem prejuízo de outras medidas, a {empresa} poderá advertir,
            suspender ou cancelar o cadastro dos usuários, bem como os eventos
            associados, negando-se a prestar os serviços descritos nestes
            Termos, caso o usuário: <br />
            a) Descumpra com qualquer disposição destes Termos ou qualquer outra
            política da {empresa}; <br />
            b) Pratique atos fraudulentos ou dolosos; <br />
            c) Dê causa a qualquer tipo de dano ou prejuízo a terceiro ou à
            própria {empresa}, na utilização da plataforma. <br />
          </p>

          <h4>
            <strong>3.Acesso à plataforma {empresa} </strong>
          </h4>
          <p>
            3.1. O Usuário é o único responsável por criar e preservar a
            confidencialidade de seus dados de login (usuário e senha), bem como
            por manter e alterar informações pessoais e/ou relativas à sua
            conta. <br />
            3.2. Os Usuários acessarão sua conta através de login e senha
            pessoais e intransferíveis, ou através de contas pessoais providas
            por terceiros, como Facebook. De qualquer maneira, os Usuários se
            comprometem a não informar a terceiros tais dados,
            responsabilizando-se integralmente pelo uso que deles seja feito, se
            comprometendo a notificar a {empresa}, imediatamente, acerca de
            quaisquer usos não autorizados de sua conta ou quaisquer outras
            violações de segurança. A {empresa} não será responsável por
            quaisquer perdas e danos resultantes de acessos não autorizados ou
            uso da conta. <br />
            3.3. Apenas o titular da conta na {empresa} poderá ter acesso aos
            dados relativos à mesma, tendo em vista seu caráter pessoal.
            Eventuais alterações em dados cadastrados somente poderão ser feitas
            pelo próprio usuário, devidamente logado. A {empresa} não tem
            prerrogativa de fazer esse tipo de alteração, independentemente da
            razão alegada ou circunstância. <br />
            3.4. Ao aceitar este Termo o Usuário concorda que a {empresa}
            poderá negar o seu cadastro, a criação e/ou manutenção de sua conta.{" "}
            <br />
          </p>

          <h4>
            <strong>
              4.Utilização das ferramentas {empresa} pelo Organizador
            </strong>
          </h4>
          <p>
            4.1. O Organizador que utilizar as ferramentas {empresa} deverá
            cadastrar o evento na plataforma, onde deverá disponibilizar, no
            mínimo, as seguintes informações, dentre outras que a {empresa}
            poderá exigir, caso a caso: data, local, endereço, horário,
            atrações, idade mínima, conteúdo do evento, serviços inclusos e/ou
            disponíveis, preços dos ingressos e política comercial (incluindo
            preços, forma de disponibilização dos tickets, política de devolução
            do dinheiro em caso de cancelamento, meia-entrada). A
            responsabilidade pela remuneração da {empresa} é exclusiva do
            organizador.
            <br />
            4.2. O Organizador é o único responsável pelo evento e por todas as
            licenças e contratações a ele relativas, inclusive das atrações,
            segurança, fornecedores, patrocinadores, exigências de órgãos
            públicos, dentre outras.
            <br />
            4.3. O Organizador deverá cuidar para não infringir os bons costumes
            e a qualquer norma legal, inclusive, mas não se limitando, relativa
            a patente, marca registrada, direito de imagem e/ou direito autoral.
            <br />
            4.4. O Organizador é o único responsável pelos links, atalhos e/ou
            redirecionamentos disponibilizados na página do evento através da
            {empresa}. A {empresa} não atesta a qualidade, veracidade e
            segurança dos mesmos.
            <br />
            4.5. O Organizador deverá informar à {empresa}, imediatamente,
            alterações e/ou cancelamento de evento.
            <br />
            4.6. O Organizador deverá cumprir todas as normas legais, inclusive
            o Código de Defesa do Consumidor, e reembolsará os valores pagos
            pelos Consumidores, caso o evento sofra alteração significativa ou
            deixe de ocorrer, independentemente do motivo.
            <br />
            4.7. A {empresa} não produz ou organiza qualquer evento, sendo
            exclusivamente uma plataforma disponibilizada ao Organizador, para
            que este realize a venda de ingressos online e físicos, bem como a
            gestão de consumo através de hardware único que permite a venda de
            produtos e serviços no sistema de fichas ou cashless. Dessa forma, o
            Organizador deverá isentar a {empresa} por qualquer responsabilidade
            relativa aos eventos comercializados através de sua plataforma. Caso
            a {empresa} venha a sofrer prejuízos decorrentes da ação ou omissão
            do Organizador, este deverá indenizá-la integralmente.
            <br />
          </p>

          <h4>
            <strong>5. Compra de ingressos</strong>
          </h4>
          <p>
            5.1. A {empresa} criou as ferramentas para que o Organizador venda e
            o Consumidor adquira seus ingressos. <br />
            5.2. Ao efetuar o login o Consumidor estará realizando o pagamento
            em ambiente seguro por meio de cartão de crédito ou boleto bancário,
            ou outra forma vigente à época, sendo estas formas de pagamento
            sujeitas à disponibilidade conforme cada evento.
            <br />
            5.3. Ao adquirir um ingresso para evento através da plataforma de
            mediação de pagamentos da {empresa} o Consumidor se compromete a
            arcar com o pagamento dos valores contratados. Identificados casos
            de má-fé, estará o Consumidor sujeito as medidas judiciais cíveis e
            criminais cabíveis.
            <br />
            5.4. Ao realizar uma compra, as seguintes regras se aplicam:
            <br />
            • Caso o Consumidor forneça dados incorretos, incompletos ou
            desatualizados, a compra poderá não ser confirmada. Nesse caso, o
            Consumidor poderá voltar a fazer uma nova compra, sempre mediante
            condições comerciais e disponibilidade de ingressos ou reservas do
            momento.
            <br />• O Consumidor deverá pagar integralmente os valores cobrados
            pelo ingresso ou reserva adquiridos através da plataforma {empresa}.
            Nos casos específicos em que o Organizador requerer que haja a
            cobrança expressa de Taxas para o Consumidor, este estará eximindo a{" "}
            {empresa} de qualquer solidariedade decorrentes da referida
            cobrança.
            <br />
            • Caso o vencimento da cobrança aconteça antes da data do evento,
            deixando o Usuário de efetuar a pagamento, poderá ocorrer a
            indisponibilidade do ingresso.
            <br />• O Consumidor deverá cuidar do ingresso e das informações
            relativas a ele, não permitindo acesso de terceiros, mantendo o
            ingresso guardado em local seguro. A {empresa} não se responsabiliza
            por ingressos adquiridos fora dos pontos de venda autorizados.
            <br />
            • Questões como alteração de data, restituição de valores nos casos
            previsto por lei e qualquer outro assunto relacionado ao evento são
            de responsabilidade exclusiva do Organizador.
            <br />• Como a {empresa} é uma plataforma que serve como ferramenta
            para os Organizadores de eventos e seus Consumidores, estes deverão
            sempre isentá-la de qualquer responsabilidade acerca dos eventos.
            <br />
            5.5. A {empresa} e as operadoras de cartão de crédito se reservam o
            direito de cancelar a compra realizada pelo Consumidor, gerando o
            cancelamento do ingresso digital ou voucher, nas hipóteses de
            inconsistência de dados, utilização indevida de cupons promocionais,
            utilização de cartão de terceiros, utilização de cadastro de
            terceiros, bem como naquelas em que a análises de segurança da
            compra pelo sistema antifraude assim o indicar, fato que será
            comunicado ao Consumidor através dos canais de atendimento da{" "}
            {empresa}.
            <br />
            5.6. Cabe ao Consumidor verificar o status de sua compra na
            plataforma {empresa} antes de se dirigir ao evento, não sendo
            cabível qualquer indenização por danos morais nas hipóteses de
            cancelamento da compra decorrentes da análise de segurança.
            <br />
            5.7. Se os compromissos acima forem desrespeitados, a {empresa}
            poderá invalidar os ingressos comprados através de sua plataforma,
            assim como suspender ou encerrar a conta do Organizador ou
            Consumidor, e recusar a disponibilizar suas ferramentas para futuras
            operações.
            <br />
          </p>

          <h4>
            <strong>6. Taxa de serviço e emissão de nota fiscal</strong>
          </h4>
          <p>
            6.1. Dependendo do contrato firmado com a organização de cada
            evento, a {empresa} poderá realizar a cobrança de uma taxa de
            conveniência para cada ingresso adquirido, a qual será incluída no
            preço total a ser pago pelo Consumidor, podendo variar conforme a
            complexidade de cada evento. <br />
            6.2. A taxa de conveniência será definida a critério da
            {` ${empresa}`}, servindo apenas para cobrir os custos do serviço
            prestado, e guardará o mais perfeito equilíbrio entre o preço do
            ingresso e o serviço disponibilizada ao Consumidor.
            <br />
            6.3. A referida taxa, quando incidente, será informada no ato da
            compra em separado do valor de face do ingresso.
            <br />
            6.4. A cobrança de taxa de serviço por intermediação de venda de
            ingressos realizada pela {empresa} diretamente do Consumidor, está
            regulada no art. 1º da Lei nº 10.986/2019, com informações que podem
            ser consultadas junto ao link:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.${empresaLowerCase}.com.br/politicas/`}
            >
              {`https://www.${empresaLowerCase}.com.br/politicas/`}
            </a>
            . <br />
            6.4.1. Ao aceitar o presente contrato antes da conclusão da compra o
            Consumidor declara estar ciente da legalidade da taxa de
            conveniência, nos termos da lei supramencionada, caracterizando ato
            de má-fé qualquer ação administrativa ou judicial do consumidor para
            exigir a sua devolução, podendo a {`${empresa} `}
            exigir o ressarcimento dos danos materiais a que der causa o
            Consumidor.
            <br />
            6.4.2. No entanto, caso o Consumidor prefira adquirir seus ingressos
            sem o pagamento da taxa de conveniência, em respeitos a disposição
            de lei alhures, poderá optar por realizar a compra do ingresso
            diretamente na bilheteria ou no ponto de venda sem cobrança de taxa
            indicado: R. Mal. Floriano Peixoto, 723 - Centro, Juiz de Fora - MG,
            36015-440.
            <br />
            6.5. Os valores dos ingressos adquiridos pelos Consumidores através
            da plataforma {empresa} são repassados ao Organizador do evento, o
            qual é o único responsável pela organização e entrega do evento
            conforme ofertado, além do pagamento de impostos e demais
            responsabilidades legais relacionadas ao evento.
            <br />
            6.6. A {empresa} promove a emissão da correspondente nota fiscal
            para cada Usuário quando das transações.
            <br />
          </p>

          <h4>
            <strong>7. Vendas e pagamentos</strong>
          </h4>
          <p>
            7.1. O processamento das compras realizadas através da ferramenta
            {empresa} e de seus respectivos pagamentos terá início na página do
            evento, que é alimentada por seu Organizador. Na página, que estará
            disponível pelo site ou aplicativo {empresa}, o Consumidor informa
            os ingressos que pretende comprar, aponta o método de pagamento que
            pretende utilizar, dentre os disponibilizados pela {empresa}, e
            finaliza sua compra. Tão logo o intermediário financeiro confirme o
            pagamento à {empresa}, esta irá realizar os procedimentos internos
            de confirmação e, após, repassar ao Organizador o valor que lhe
            couber, deduzida a taxa de serviço. <br />
            7.2. Qualquer operação que envolver compra, pagamento e/ou repasse
            de recursos, poderá passar por processo interno de validação pela
            {empresa}.
            <br />
          </p>

          <h4>
            <strong>8. Entrega e utilização de ingressos</strong>
          </h4>
          <p>
            8.1. Os ingressos comprados através da plataforma {empresa}
            serão enviados ao e-mail indicado pelo Consumidor tão logo o
            pagamento seja aprovado. Pagamentos aprovados em tempo real
            habilitam que os ingressos também sejam baixados na própria tela de
            confirmação da compra.
            <br />
            8.2. Cabe ao Consumidor deixar seus sistemas de anti-spam, filtros
            ou similares, de modo que não interfiram no recebimento dos
            comunicados e materiais da {empresa}, não sendo aceitável nenhuma
            escusa caso não tenha tido acesso a algum e-mail ou mensagem
            eletrônica em virtude dos recursos mencionados, o que não gerará
            qualquer tipo de expectativa de direito ou indenização ao Consumido
            <br />
            8.3. Além da entrega por meio de mensagem eletrônica, os ingressos
            também poderão ser acessados na área autenticada (logada) do site.
            Para isso, o Consumidor deve criar um usuário, gratuitamente, na
            plataforma {empresa}, fornecendo o mesmo e-mail utilizado no ato da
            compra. O ingresso pode ser acessado na área “Meus Ingressos” do
            site.
            <br />
            8.4. Os ingressos são associados a um código alfanumérico único,
            representado em algarismos, “QR Code” e Código de Barras
            (exclusivamente no formato PDF). Após o recebimento, caberá ao
            Consumidor mantê-los em segurança, não divulgando-os publicamente,
            sobretudo em redes sociais, ou permitindo o acesso de terceiros a
            ele.
            <br />
            8.4.1. Os ingressos comprados através da plataforma {empresa}
            são nominais, cabendo ao Organizador decidir pela conferência dessa
            informação no momento de acesso ao evento. A não conferência dessa
            informação não gerará qualquer tipo de expectativa de direito ou
            indenização.
            <br />
            8.5. Os ingressos adquiridos na plataforma {empresa} podem ser
            usados para acesso ao evento uma única vez. O check-in do
            participante, ou seja, a leitura e validação do ingresso do
            Consumidor, através do identificador único, é de responsabilidade do
            Organizador. O acesso do participante ao evento não será liberado
            caso o check-in do ingresso já tenha sido realizado anteriormente,
            salvo por decisão exclusiva do Organizador.
            <br />
            8.6. A 2ª via de ingresso digital pode ser impressa a qualquer
            momento pelo Consumidor na área do cliente na plataforma
            {empresa}.
            <br />
          </p>

          <h4>
            <strong>9. Transferência de titularidade dos ingressos</strong>
          </h4>
          <p>
            9.1. Somente o Consumidor, titular da compra, poderá solicitar a
            transferência dos ingressos para outro usuário ou receber
            informações detalhadas sobre a transação efetuada na plataforma{" "}
            {empresa}. Os dados associados ao Consumidor, titular da compra, não
            podem ser alterados após a confirmação desta compra. <br />
            9.2. O participante não poderá solicitar alterações em seus
            ingressos, as quais poderão ser feitas somente pelo Consumidor,
            salvo quando participante e Consumidor forem a mesma pessoa.
            <br />
            9.3. A {empresa} pode ou não realizar a troca de titularidade do
            ingresso quando houver suspeita de fraude, não sendo obrigada a
            comprovar a fraude.
            <br />
            9.4. A troca de titularidade deverá ser solicitada por e-mail,
            através do endereço {email}. Nesses casos, as instruções para
            encaminhar a solicitação estarão disponíveis na página do evento na
            plataforma {empresa}.
            <br />
            9.5. A troca de titularidade poderá ser realizada apenas 01 (uma)
            vez, com até 48 (quarenta e oito) horas de antecedência do horário
            previsto para início do evento, conforme informações contidas na
            página deste evento.
            <br />
            9.6. Ao receber o ingresso via troca de titularidade, o Consumidor
            declara-se ciente de que o titular da compra poderá, a qualquer
            momento, e desde que autorizado pelo Organizador, cancelar a compra
            ou efetuar uma nova troca de titularidade, inviabilizando, assim,
            seu acesso ao evento. A {empresa} não responsabiliza por compras
            efetuadas fora da plataforma {empresa}
            ou em seus pontos de venda oficiais.
            <br />
            9.7. Ao receber o ingresso via troca de titularidade, o Consumidor
            declara-se corresponsável pela compra. Caso seja comprovada alguma
            fraude na compra do ingresso o consumidor poderá ser
            responsabilizado a indenizar o dano causado.
            <br />
          </p>

          <h4>
            <strong>
              10. Desistência, cancelamento e reembolso de transações
            </strong>
          </h4>
          <p>
            10.1. O Consumidor poderá exercer o direito de arrependimento da
            compra no prazo de até 7 (sete) dias contados da compra, desde que
            este prazo não ultrapasse o limite de 48 (quarenta e oito) horas
            úteis antes do início do evento.
            <br />
            10.2. O cancelamento da compra deverá ser formalizado por escrito
            através da central de atendimento acessível pela seção Fale Conosco
            na plataforma do site da {empresa}.
            <br />
            10.3. Em caso de compras por cartão de crédito o reembolso do
            Consumidor ocorrerá por crédito no próprio cartão utilizado para a
            compra. Em caso de pedido por boleto bancário, o reembolso será
            realizado por depósito bancário, no prazo de até 20 (vinte) dias da
            data da solicitação de cancelamento que contenha os dados bancários
            completos para efetivação da devolução.
            <br />
            10.4. Ocorrendo recusa por parte do Consumidor em realizar o
            procedimento de cancelamento conforme as instruções ou não
            fornecendo os dados bancários para estorno, não poderá reclamar
            qualquer responsabilização da {empresa} pelo não atendimento da
            solicitação de devolução, por se tratar de ato de culpa exclusiva do
            consumidor.
            <br />
            10.5. Todas as reclamações e pedidos de desistência realizados após
            as 48 horas úteis que antecedem o evento deverão ser encaminhados
            diretamente à organização do evento, estando a {empresa} isenta de
            qualquer responsabilidade na apreciação do pedido.
            <br />
            10.5.1. Quando a solicitação ocorrer após a realização do evento.
            Todo o contato deverá ser feito diretamente com a organização do
            evento que será responsável pela resposta e possível devolução de
            valores.
            <br />
            10.5.2 O consumidor isenta a {empresa} de toda a responsabilidade
            sobre devolução de valores após a realização do evento visto que
            todos os valores já terão sido repassados à organização
            <br />
            10.6. O Consumidor será responsável por qualquer equívoco ou atraso
            no cadastramento dos dados bancários para reembolso dos valores.
            10.7. Se identificarmos que algum evento não foi realizado ou, foi
            realizado de maneira distinta ao anunciado, a{empresa} se resguarda
            o direito de reembolsar todas as compras ou mesmo suspender,
            provisoriamente, o valor do repasse até que a situação esteja
            regularizada entre todas as partes envolvidas na transação, quais
            sejam, {empresa}, Organizador e Consumidores.
            <br />
            10.8. Especificamente em casos de compras com indícios de fraude, a{" "}
            {empresa} poderá solicitar ao Consumidor a cópia digitalizada dos
            documentos do titular do cartão de crédito utilizado nas compras,
            bem como do próprio cartão utilizado, visando legitimá-las. Neste
            caso, o contato será feito através dos nossos canais oficiais.
            <br />
            10.8.1. O prazo de envio da documentação do parágrafo acima será
            estabelecido pela {empresa}, de acordo com a data e horário em que o
            evento ocorrerá, de forma que, remetido o documento pelo Consumidor,
            ocorrerá a análise do mesmo e o retorno através do mesmo e-mail
            utilizado para a compra, ou outro canal de contato oficial.
            <br />
            10.8.2. Caso os documentos não sejam enviados pelo Consumidor no
            prazo determinado, ficará a cargo da {empresa}
            cancelar/suspender, ou não, a transação, a seu exclusivo critério.
            <br />
            10.9. Na ocorrência de impedimento à realização do evento, ligado a
            caso fortuito (guerra, motim, dentre outros) ou força maior
            (fenômenos da natureza, epidemias, pandemias dentre outros), o
            Organizador deverá cancelar o evento e realizar a devolução dos
            valores para os Consumidores, se de outra forma não se dispor por
            ato ou lei.
            <br />
          </p>

          <h4>
            <strong>11. Contestações e estornos</strong>
          </h4>
          <p>
            11.1. A {empresa} adotará sempre as medidas necessárias para evitar
            que fraudes sejam aplicadas através de suas ferramentas, quer pelo
            site, quer através do aplicativo. <br />
            11.2. As contestações reportadas serão analisadas pela operadora de
            cartão de crédito, por nossos processadores de pagamentos, ou pela
            própria {empresa}.
            <br />
            11.3. Tendo em vista os investimentos que faz e as normas e
            procedimentos de segurança que a {empresa} adota a fim de evitar a
            ocorrência de fraudes, em nenhuma hipótese a {empresa} poderá ser
            responsabilizada por prejuízos causados por terceiros.
            <br />
            11.4. Os estornos ocorridos nas vendas realizadas de quaisquer
            eventos vendidos através da plataforma {empresa} ficarão na
            responsabilidade do Organizador, independentemente da razão da
            contestação.
            <br />
            11.5. A {empresa} irá conduzir as disputas abertas pelos
            Consumidores, tais como, mas não somente, chargebacks
            (contestações), reclamações diversas, cancelamento e estornos.
            Assim, irá iniciar e processar cancelamentos sem a necessidade da
            intervenção ou aprovação do Organizador, nos casos de:
            <br />
            a) não cumprimento da política de reembolsos do evento e/ou do
            Código de Defesa do Consumidor, por parte do Organizador;
            <br />
            b) detecção de indícios de fraude em compras realizadas, estejam
            elas pendentes de aprovação ou já aprovadas, sejam tais riscos
            provenientes do Consumidor ou do Organizador;
            <br />
            c) cancelamento do evento;
            <br />
            d) erro técnico no processamento da transação;
            <br />
            e) verificarmos um número elevado de queixas referentes a um
            determinado evento, por parte dos Consumidores.
            <br />
          </p>

          <h4>
            <strong>12. Responsabilidade da {empresa}</strong>
          </h4>
          <p>
            12.1. A responsabilidade da {empresa} é limitada exclusivamente aos
            serviços por ela prestados, quais sejam, os serviços de
            intermediação de ingressos via plataforma com a emissão de ingressos
            digital. <br />
            12.2. A {empresa} não é Organizadora de eventos, portanto, não tem
            qualquer tipo de vínculo ou sociedade com o Organizados de eventos.
            De tal modo não redige suas publicidades, não contrata atrações ou
            serviços bem como não tem acesso aos eventos, razão pela qual
            informa aos Consumidores no ingresso digital ou ingresso físico, os
            dados do Organizador do evento. <br />
            12.3. Por se tratar de plataforma de mediação de pagamentos e
            ingressos, a responsabilidade da {empresa} é regida pela Lei nº
            12.965/14 (Marco Civil da Internet), a qual limita sua
            responsabilidade aos serviços e informações que efetivamente presta
            ao Consumidor/Usuário, não podendo responder por danos decorrentes
            das informações ou publicidade do Organizador, ainda que inseridas
            na plataforma, bem como por danos decorrentes do evento em si, tais
            como interrupção, cancelamento, mudanças de local, data ou horário,
            substituição de artistas, não prestação dos serviços anunciados,
            danos civis ou morais aos usuários, entre outros. <br />
          </p>

          <h4>
            <strong>13. Responsabilidade do Organizador</strong>
          </h4>
          <p>
            13.1. Ao realizar o cadastro do evento na plataforma {empresa}, o
            Organizador declara ser o titular de todos os direitos referentes ao
            evento inclusive direitos autorais. <br />
            13.2. O Organizador declara ainda ser o legítimo titular do evento
            publicado, respondendo por todos os atos e/ou danos decorrentes do
            evento anunciado na plataforma da {empresa}.
            <br />
            13.3. Toda a informação disponibilizada, publicidade, vídeo ou
            banner referente ao evento divulgado na plataforma {empresa}{" "}
            constitui conteúdo de responsabilidade exclusiva do Organizador, não
            tendo a {empresa} qualquer ingerência sobre o conteúdo de informação
            ou publicidade do evento ofertado ou sua insuficiência de
            informações, cabendo única a exclusivamente ao Organizador do evento
            a responsabilização nos termos dos arts. 12 e 14 do Código de Defesa
            do Consumidor.
            <br />
            13.4. Todas as informações e publicidades constantes no site
            referentes ao evento cadastrado na plataforma {empresa} são
            incluídas pelo Organizador e são de sua responsabilidade exclusiva,
            o qual responde por eventuais vícios de qualidade e demais danos
            decorrentes do evento, caso existentes.
            <br />
            13.5. A venda de ingressos com o benefício da modalidade
            meia-entrada constitui responsabilidade do Organizador do evento,
            nos termos da legislação da meia-entrada vigente. A {empresa} por
            sua vez, disponibiliza aos Consumidores página específica em sua
            plataforma com todas as informações referente a Legislação Federal e
            legislação específicas de meia entrada em Estados e Municípios, para
            total conhecimento do Consumidor. Eventuais descumprimentos por
            parte do organizador podem ser comunicados à {empresa} através dos
            canais de atendimento ao consumidor.
            <br />
            13.6 Dar suporte aos consumidores após a realização do evento, bem
            como se responsabiliza por quaisquer cobranças de devoluções ou
            cobranças indevidas.
            <br />
          </p>

          <h4>
            <strong>14. Responsabilidades e obrigações dos Consumidores</strong>
          </h4>
          <p>
            14.1. Os Consumidores se obrigam a utilizar a plataforma
            {empresa} de maneira ética e adequada aos propósitos estabelecidos
            nestes Termos, atendendo ao disposto nas leis brasileiras,
            comprometendo-se a se absterem de qualquer atividade que constitua
            violação das referidas normas. <br />
            14.2. Ao utilizarem a plataforma {empresa} e suas funcionalidade, os
            Consumidores se obrigam a: <br />
            a) Inserir dados corretos, completos e atualizados na aquisição
            (onerosa ou gratuita) dos ingressos. Caso contrário, a aquisição
            poderá ser invalidada e o Consumidor, caso queira, terá que fazer
            uma nova aquisição, estando sujeito às condições e disponibilidade
            de ingressos, inscrições ou contribuições nesse segundo momento;{" "}
            <br />
            b) Arcar com os valores cobrados na fatura de cartão de crédito,
            caso essa seja a forma de pagamento escolhida pelo Consumidor. A
            cobrança poderá vir em nome da {empresa}, ou algum de nossos
            processadores de pagamentos; <br />
            c) Arcar com o valor total e integral do boleto e pagá-lo até a data
            do seu vencimento, caso o Consumidor tenha optado por esse método de
            pagamento. Após a data limite de pagamento, o boleto deixa de ser
            válido para a compra e o ingresso não estará mais reservado para o
            Consumidor. <br />
            d) Comprar os ingressos apenas através da plataforma {empresa}, ou
            em pontos de venda autorizados. A {empresa} não se responsabiliza e
            não atesta a veracidade ou confiabilidade de ingressos adquiridos de
            terceiros, outros sites ou qualquer ponto de venda não autorizado,
            ficando o Consumidor ciente que ao fazer tal aquisição estará
            correndo o risco de ser vítima de esquema de fraude ou engenharia
            social; <br />
            e) Participar do processo de apuração de eventuais fraudes nas
            transações efetuadas, realizado por meio de sistema de análise de
            risco da {empresa} e de seus parceiros. A análise de risco da
            transação é feita com base em padrões históricos, conferência dos
            dados cadastrais e bases externas, podendo resultar numa
            probabilidade de que determinada transação seja fraudulenta. Feita a
            avaliação de risco transacional, a compra poderá ser aprovada ou
            não, segundo critérios pré-estabelecidos. A não aprovação não gerará
            expectativa de direito, tampouco indenização de qualquer espécie;{" "}
            <br />
            f) Fornecer todas as informações e documentos que forem solicitados
            através dos canais oficiais da {empresa}, caso seja necessária
            alguma validação da compra por parte da {empresa} ou de nossos
            processadores de pagamentos. <br />
            g) Apresentar, sempre que requerido, na portaria do evento, um
            documento de identidade com a foto do titular do cartão e/ou o
            cartão utilizado na compra, caso tenha adquirido ingressos via
            cartão de crédito, sob pena de ter o direito de acesso ao evento
            negado, sem que tal fato gere direito a indenização de qualquer
            espécie; <br />
            h) Promover a segurança do ingresso a partir do momento em que o
            receber, não divulgando-o publicamente, sobretudo em redes sociais,
            ou permitindo o acesso de terceiros a ele; <br />
            i) Arcar com a taxa eventualmente cobrada pela processadora de
            pagamentos, para fins de saque do crédito decorrente do estorno, no
            caso de cancelamento da compra por parte do Consumidor; <br />
            j) Isentar a {empresa} de qualquer responsabilidade sobre a
            realização dos eventos para os quais foram adquiridos ingressos. A
            {empresa} não é Organizadora de eventos. Fornecemos a plataforma
            para que os Organizadores vendam seus ingressos e gerenciem seus
            participantes. <br />
            14.3. Em todos os casos, a {empresa}, a seu exclusivo critério,
            poderá ainda se recusar a prestar quaisquer serviços,
            independentemente da motivação, sem que isso constitua limitação de
            direitos. <br />
          </p>

          <h4>
            <strong>15. Direitos de Propriedade Intelectual</strong>
          </h4>
          <p>
            15.1. O uso comercial da expressão "{empresa}" como marca, nome
            empresarial ou nome de domínio, bem como os conteúdos das telas
            relativas aos serviços da {empresa}, assim como os programas, “look
            and feel” dos sites, bancos de dados, redes, arquivos que permitem
            ao usuário acessar e usar a sua conta, são de titularidade da{" "}
            {empresa} e estão protegidos pelas leis e tratados internacionais de
            direito autoral, marcas, patentes, modelos e desenhos industriais. O
            uso indevido e a reprodução total ou parcial dos referidos conteúdos
            são proibidos, salvo a autorização prévia e expressa por escrito da{" "}
            {empresa}.
            <br />
            15.2. As páginas dos eventos poderão conter links para outros sites,
            o que não significa que esses sites sejam de propriedade ou operados
            pela {empresa}, que não possui controle sobre esses sites de
            terceiros, razão pela qual não será responsável pelos seus
            conteúdos, práticas e serviços ofertados. A presença de links para
            outros sites não implica relação de sociedade, de supervisão, de
            cumplicidade ou solidariedade da {empresa} para com esses sites e
            seus conteúdos. <br />
          </p>

          <h4>
            <strong>16. Privacidade dos Usuários</strong>
          </h4>
          <p>
            16.1. A {empresa} possui documento próprio, denominado “Política de
            Privacidade”, que regula o tratamento dado às informações e dados
            pessoais coletados através da plataforma {empresa} e suas
            funcionalidades. <br />
            16.2. A “Política de Privacidade” é parte integrante e inseparável
            dos Termos de Uso da plataforma {empresa} e pode ser acessada no
            link encontrado em seu rodapé. <br />
            16.3. Caso alguma disposição da “Política de Privacidade” conflite
            com qualquer outra do presente documento, deverá prevalecer o
            descrito na norma mais específica. <br />
          </p>

          <h4>
            <strong>
              17. Proteção de registros, informações e comunicações privadas
            </strong>
          </h4>
          <p>
            17.1. A {empresa} somente será obrigada a disponibilizar registros
            de acesso, informações pessoais ou comunicações privadas armazenadas
            em sua plataforma mediante ordem judicial ou requisição de
            autoridade policial ou administrativa competente.
            <br />
            17.2. Em todos os casos, a {empresa} comunicará o Organizador da
            existência do pedido de revelação, ficando desde já autorizada a
            responder a requisição recebida. <br />
            17.3. Visando a proteção dos direitos dos Usuários e de terceiros,
            caso ocorra a tentativa ou a efetiva utilização indevida dos
            serviços da plataforma, a {empresa} poderá indicar os dados do
            Usuário às autoridades públicas, aos serviços de proteção ao
            crédito, dentre outros, para início dos procedimentos legais e
            administrativos cabíveis.
            <br />
          </p>

          <h4>
            <strong>18. Limitação da responsabilidade da {empresa}</strong>
          </h4>
          <p>
            18.1. Fica expressamente estabelecido que a {empresa} não tem
            obrigação de controlar todas as ações executadas pelos Usuários no
            uso da plataforma {empresa} e, por conseguinte, esta não poderá ser
            responsabilizada pelos atos de seus usuários, inclusive aqueles de
            caráter ilícito, imoral ou antiéticos porventura perpetrados,
            cabendo à estes responderem pessoal e exclusivamente por eventuais
            reclamações de terceiros ou demandas judiciais, devendo manter a{" "}
            {empresa} live de qualquer responsabilidade ou ônus. <br />
            18.2. A {empresa} não produz e não é responsável por qualquer
            evento. A responsabilidade da {empresa} se limita a disponibilizar
            meios para que os Organizadores de eventos comercializem ingressos.{" "}
            <br />
            18.3. A {empresa} não se responsabiliza por qualquer dano, direto ou
            indireto, ocasionado por eventos de terceiros, como ataque de
            hackers, falhas no sistema, no servidor ou na conexão à internet,
            inclusive por ações de programas maliciosos como vírus, cavalos de
            troia, e outros que possam, de algum modo, danificar o equipamento
            ou a conexão dos usuários em decorrência do acesso, utilização ou
            navegação na plataforma {empresa}, salvo se tal fato decorrer de
            dolo ou culpa da {empresa}. <br />
            18.4. A plataforma {empresa} ou qualquer funcionalidade ou parte
            desta pode não estar disponível devido eventual atualização do
            sistema, por falhas do mesmo ou devido a fatores externos. Na medida
            em que escolherem aceitarem e utilizar a {empresa}, os Usuários
            estarão fazendo por iniciativa própria, e concordando que as
            ferramentas podem vir a ficar indisponíveis. <br />
            18.5. Os Usuários concordam que a {empresa} Tecnologia Ltda, seus
            sócios, funcionários e prepostos não são responsáveis, em nenhuma
            hipótese, por reparar quaisquer prejuízos, materiais ou morais,
            relacionados à utilização da plataforma {empresa}. <br />
          </p>

          <h4>
            <strong>19. Disposições gerais</strong>
          </h4>
          <p>
            19.1. A {empresa} não se responsabiliza por qualquer dano ou
            prejuízo com envio de informações ou de conteúdo publicitário que
            não tenham sido disparados pela própria {empresa}, e também não se
            responsabiliza pelo conteúdo das opiniões postadas na página dos
            eventos. <br />
            19.2. O Usuário declara ter ciência de que a {empresa} não é
            organizadora de eventos, mas tão somente plataforma de mediação de
            pagamentos para aquisição dos ingressos de eventos dos
            Organizadores. Eventuais reclamações ou ações judiciais decorrentes
            de fatos do evento deverão ser direcionadas exclusivamente ao
            Organizador do evento, o qual está devidamente identificado no
            ingresso digital ou ingresso físico, nos termos da Lei do Marco
            Civil da Internet.
            <br />
            19.3. Ao navegar pela plataforma {empresa} e utilizar suas
            funcionalidades, os usuários aceitam todo o disposto nos Termos de
            Uso e demais políticas legais que se encontram vigentes na data de
            acesso. Por isso, é recomendável que os Usuários se mantenham
            atualizados.
            <br />
            19.4. Nossos serviços são direcionados para pessoas maiores de 18
            (dezoito) anos, com documento pessoal e endereço de e-mail válidos,
            ou ainda, para menores e incapazes, desde que devidamente
            autorizados ou assistidos por seus representantes legais.
            <br />
            19.5. As regras e condições de uso ora apresentadas poderão ser
            alteradas a qualquer tempo sem necessidade de comunicação prévia da{" "}
            {empresa}. Ao realizar o cadastro na plataforma {empresa} o
            Consumidor estará concordando expressamente com as regras e
            condições de utilização ora apresentadas, nos termos da legislação
            vigente.
            <br />
            19.6. A {empresa} se reserva o direito de bloquear o cadastro do
            Consumidor e impedir usos futuros, cancelar a compra de ingressos
            conforme análise de segurança, bem como tomar as medidas judiciais
            cabíveis, na hipótese de violação das regras e condições
            explicitadas neste contrato, não sendo cabível qualquer pedido de
            indenização em decorrência do cancelamento do cadastro. 19.7. A
            eventual tolerância quanto a qualquer violação dos termos e
            condições do contido neste domínio será considerada mera
            liberalidade e não será interpretada como novação, precedente
            invocável, renúncia a direitos, alteração tácita dos termos
            contratuais, direito adquirido ou alteração contratual.
            <br />
            19.8. Caso alguma disposição destes Termos de Uso for julgada
            inaplicável ou sem efeito, o restante das normas continua a viger,
            sem a necessidade de medida judicial que declare tal assertiva. Os
            Termos aqui descritos serão interpretados segundo a legislação
            brasileira.
            <br />
            19.9. A comunicação entre a {empresa} e o usuário deverá ser
            realizada pelos canais de atendimento indicados e disponibilizados
            na plataforma {empresa}, sobretudo através do endereço de e-mail
            {link}.
            <br />
            19.10. Sempre que possível, potenciais divergências entre o usuário
            e a {empresa} serão resolvidas de forma amigável. Quando todos os
            esforços neste sentido forem esgotados, você concorda, desde já, que
            fica eleito o foro da comarca de Fortaleza, no Estado do Ceará, para
            resolver controvérsias ou queixas oriundas da utilização de nossa
            plataforma ou relacionadas a estes Termos de Uso.
            <br />
          </p>
          <h4>
            <strong>PAGAMENTO</strong>
          </h4>
          <p>
            As formas de pagamento do ingresso (crédito, débito, pix, picpay e
            boleto) podem variar de acordo com cada evento. Verifique
            disponibilidade; Em caso de parcelamento da compra do ingresso, o
            cliente estará sujeito a taxa de juros da operadora de crédito do
            cartão utilizado para compra, bem como a taxa de serviços da
            plataforma de pagamentos online (Pagar-me); Não deve ser utilizado
            cartão de crédito ou debito de terceiros para a compra de ingresso.
            Essa prática pode resultar na recusa do pagamento do pedido pela
            plataforma de pagamentos on-line (Pagar-me), durante a análise da
            compra;
          </p>
          <h4>
            <strong>ANÁLISE DA COMPRA/APROVAÇÃO</strong>
          </h4>
          <p>
            Todas as compras realizadas em nosso site estão sujeitas a uma
            análise de dados, crédito e segurança realizada pela plataforma de
            pagamentos on-line (Pagar-me); Essa análise é subjetiva, livre de
            interação, não armazena os dados pessoais e bancários, e é realizada
            de forma independente pela plataforma de pagamentos on-line
            (Pagar-me). Nesse contexto, a {empresa} não atua como operador ou
            controlador de dados bancários, não coleta, não armazena, não trata
            esses dados do adquirente. Se o ingresso tiver acento marcado, a
            reserva do local escolhido só será confirmada após finalização da
            compra; Um e-mail com a confirmação do pagamento será enviado após a
            aprovação da compra na análise. Após o recebimento desse e-mail, o
            cliente já poderá imprimir o bilhete em seu acesso pessoal/conta em
            nosso site stingressos.com.br; Caso o pedido seja recusado após a
            análise de dados, crédito e segurança, a plataforma de pagamentos
            on-line (Pagar-me) não nos repassará o resultado final dessa
            análise, em respeito ao sigilo de informações do cliente; Ao aceitar
            esse termo, o usuário declara conhecer e aceitar que as informações
            solicitadas no momento do cadastro do titular e no momento da
            confirmação de pagamento da compra sejam utilizadas nessa análise.
          </p>

          <h4>
            <strong>TROCA DO PORTADOR DO BILHETE</strong>
          </h4>
          <p>
            O titular do bilhete é o mesmo titular da conta em nosso site
            stingressos.com.br e, consecutivamente, o mesmo titular do cartão
            utilizado para realizar a compra. A titularidade é intransferível,
            sendo proibida a revenda a terceiros. Caso haja entrega de abadá, só
            será permitida a troca de titularidade até antes da retirada do
            abadá. Após a retirada do abadá não será possível a troca de
            titulridade; Portador é a pessoa que detém o uso do ingresso. Ele(a)
            apresentará o ingresso na entrada do evento, podendo ser a mesma
            pessoa do titular; A transferência do uso do bilhete só será
            possível após a aprovação da compra na análise realizada pela
            plataforma de pagamentos on-line (Pagar-me), no prazo de até 72h
            (setenta e duas horas) antes da data do evento; Para transferir um
            bilhete é necessário que o(a) titular da conta nos envie o nome
            completo, RG e CPF do portador destinatário que irá usar o bilhete,
            pela página de contato no site stingressos.com.br ou direto para
            nosso endereço de e-mail de atendimento ao cliente
            atendimento@stingressos.com.br. Ao nos enviar a solicitação de
            transferencia, o cliente declara que todos os dados fornecidos para
            a troca são verídicos. Não nos responsabilizamos por dados
            incompletos ou errados. Recomendamos que o cliente confira
            atentamente os dados informados antes de enviá-los; Não será
            possível transferir o ingresso se o portador destinatário não
            possuir CPF válido.
          </p>
          <h4>
            <strong>TERMO DE CONSENTIMENTO</strong>
          </h4>
          <p>
            Em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados
            Pessoais (LGPD), o cliente autoriza e tem expresso conhecimento da
            utilização dos dados pessoais coletados (nome, documento, e-mail e
            senha), sendo de legítimo interesse a coleta dos dados em razão do
            que dispõe o inciso V, 7º da legislação, para que possamos enviar as
            condições do produto/serviço contratado e para que possamos manter o
            usuário integralmente informado do andamento de solicitações,
            reclamações eventualmente enviadas ao nosso conhecimento e
            providências, e envio de informativos, divulgação de serviços e/ou
            produtos.
          </p>
          <h4>
            <strong>
              GUARDA CONSERVAÇÃO E NÃO COMPARTILHAMENTO DE DADOS PESSOAIS
            </strong>
          </h4>
          <p>
            a) A {empresa} atua em observância as regras de complience na
            ocasião dos tratamentos de dados, garantindo assim a guarda e
            conservação, utilizando todos os mecanismos disponível para proteção
            das informações disponibilizadas pelo cliente, e salvo para eventual
            necessidade do cumprimento deste contrato, não divulga ou
            compartilha nenhuma informação aqui coletada com terceiros.
            <br></br>
            b) Não serão coletados dados sensíveis de nossos usuários, assim
            entendidos aqueles definidos nos arts. 11 e seguintes da Lei de
            Proteção de Dados Pessoais (Lei n. º 13.709/2018). Assim, não haverá
            coleta de dados sobre origem racial ou étnica, convicção religiosa,
            opinião política, filiação a sindicato ou a organização de caráter
            religioso, filosófico ou político, dado referente à saúde ou à vida
            sexual, dado genético ou biométrico, quando vinculado a uma pessoa
            natural.
            <br></br>
            c) Os dados pessoais coletados pelo site são armazenados e
            utilizados por período de tempo que corresponda ao necessário para
            atingir as finalidades elencadas neste documento e que considere os
            direitos de seus titulares, os direitos do controlador do site e as
            disposições legais ou regulatórias aplicáveis. Uma vez expirados os
            períodos de armazenamento dos dados pessoais, eles são removidos de
            nossas bases de dados ou anonimizados, salvo nos casos em que houver
            a possibilidade ou a necessidade de armazenamento em virtude de
            disposição legal ou regulatória.
          </p>
        </div>
      </div>
    </>
  );
}
