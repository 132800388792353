/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useZendesk } from "../../hooks/useZendesk";

export default function Aplicativos() {
  const [aplicativos, setAplicativos] = useState([]);
  const { hide } = useZendesk();
  useEffect(() => {
    hide();
    setAplicativos([
      {
        nome: "Aplicativo de Check-in",
        link: "https://seutickets-assets.s3.amazonaws.com/apps/seutickets-checkin.apk",
      },
    ]);
  }, []);
  return (
    <>
      <div id="main-events" className="container">
        <h2>Aplicativos</h2>
        <div className="events-list">
          <div className="event-list-row">
            {aplicativos.map((aplicativo, index) => (
              <p key={index}>
                <a href={aplicativo.link}>{aplicativo.nome}</a>
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
