/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./index.css";
import { removeCheckoutData } from "../../../Helpers/checkout";
import { useZendesk } from "../../../hooks/useZendesk";

export default function Index() {
  const { hide } = useZendesk();
  useEffect(() => {
    window.scrollTo(0, 0);
    hide();
    removeCheckoutData();
  }, []);

  return (
    <div className="container info-containers mt-4 mb-4">
      <div className="row terms">
        <h1>Políticas de compra</h1>
        <p>
          Conforme o Código de Defesa do Consumidor (Artigo 49), somente serão
          aceitos pedidos de cancelamentos de compras solicitados até 7 dias
          após o pedido, desde que não ultrapasse o limite de 24hrs antes do
          início do evento.
        </p>
        <h4>
          <strong>Cancelamentos, Trocas de Horários e Datas de Eventos</strong>
        </h4>
        <p>
          Em casos de cancelamento, troca de horário e data do evento, é direito
          do cliente solicitar a devolução do dinheiro referente aos ingressos
          do evento em questão. Clientes que compraram seus ingressos através do
          site da ST Ingressos, devem entrar em contato pelo Fale Conosco ou por
          e-mail solicitando a devolução.
        </p>
        <h4>
          <strong>Taxa de Serviço</strong>
        </h4>
        <p>
          Dependendo do contrato firmado com a organização de cada evento, a
          ST Ingressos poderá realizar a cobrança uma taxa de conveniência por
          cada ingresso adquirido, a qual será incluída no preço total a ser
          pago pelo USUÁRIO, podendo variar conforme a complexidade de cada
          evento. A taxa de conveniência será definida a critério da equipe ,
          servindo apenas para cobrir os custos do serviço prestado, e guardará
          o mais perfeito equilíbrio entre o preço do ingresso e a conveniência
          disponibilizada ao USUÁRIO
        </p>
      </div>
    </div>
  );
}
